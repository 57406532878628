import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
  a {
    text-decoration: none;
    color: #8fccb5;
  }
`
const Boldie = styled.div`
  font-weight: bold;
`

const Contacto = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Contacto</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              Tenemos la experiencia para convertir su proyecto en una realidad
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/contacto.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              <Boldie>Mas de 10 años de Experiencia</Boldie>
              <br /> <br />
              El equipo de ingenieros y creativos de Yaku, está a su
              disposición. De acuerdo a sus requerimientos de negocio nuestro
              equipo de profesionales planificará minuciosamente cual es la
              estrategia adecuada para su plan de negocio. <br /> <br />
              Estamos prestos a servirle, mantenemos soporte 24/7/365 para todos
              nuestros servicios y productos. <br /> <br />
              Cuéntenos cual es su nuevo proyecto, estamos listos para
              asesorarle. Por favor enviar un correo a {" "}
              <a href="mailto:info@yaku.com.ec">info@yaku.com.ec</a> <br />
              <br />
              En Yaku proporcionamos asesoría y puesta en marcha de proyectos de
              aplicaciones móviles. Transformamos su sitio web en una versión
              móvil multifuncional o creamos una aplicación desde cero.
              <br /> <br />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Contacto
